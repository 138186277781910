body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  height: 100%;
}
* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  --infinite-active-cell-border-color: black;
  --infinite-active-cell-border: solid;

}

.Infinite {
  flex: 1;
}

.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s;
}

.clickable-row:hover {
  background-color: #f5f5f5;
}

.footer {
  background-color: rgb(25, 118, 210);
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.footer .MuiTypography-root {
  color: white;
}

.dash-button-group {
  gap: 2px;
}

.dash-button {
  border: none !important;
  background: none;
}

.dash-button:hover,
.dash-button:focus {
  border: none !important;
  outline: none;
}


